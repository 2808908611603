import { ConfigType } from './config.type'
import { ValueOf } from 'utils'

type ConfigKeys = 'PEERTJELABS'

const loadConfig = <T extends keyof ConfigType, K extends ValueOf<ConfigKeys>>(
  configKey: T,
  configType: K,
) => {
  return Object.keys(__APP_CONFIG__)
    .filter(k => k.split('_')[0] === configType)
    .reduce(
      (conf, next) => ({
        ...conf,
        [next.split('_').slice(1).join('_')]: __APP_CONFIG__[next],
      }),
      {},
    ) as ConfigType[T]
}

export const appConfig: ConfigType = {
  peertjelabs: loadConfig('peertjelabs', 'PEERTJELABS'),
}
