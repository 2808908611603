import styled, { css } from 'styled-components'
import { zIndex } from 'styled-system'

import { ThemeInterface } from 'theme'
import { convertPxToRem } from 'utils/styles'
import { ButtonProps, ButtonSizes, ButtonStyles } from './button.types'

export const ButtonReset = css`
  appearance: none;
  background: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  margin: 0;
  outline: 0;
  padding: 0;

  &[disabled] {
    pointer-events: none;
  }
`

const BUTTON_BORDER_WIDTH = convertPxToRem(1.5)
const BUTTON_BLUE_SHADOW = '0 2px 5px 0 rgba(0, 102, 204, 0.3)'

const ButtonSolid = css`
  background-color: ${({ theme }: { theme: ThemeInterface }) =>
    theme.colors.brandBlue};
  color: ${({ theme }: { theme: ThemeInterface }) => theme.colors.white};

  &:hover,
  &:focus {
    background-color: ${({ theme }: { theme: ThemeInterface }) =>
      theme.colors.brandBlueDark};
  }

  &[disabled] {
    opacity: 0.3;
  }
`

const ButtonSecondary = css`
  background-color: ${({ theme }: { theme: ThemeInterface }) =>
    theme.colors.blueMarineAlpha27};
  color: ${({ theme }: { theme: ThemeInterface }) => theme.colors.brandBlue};
  transition: ${({ theme }: { theme: ThemeInterface }) =>
    theme.transitions.default};

  &:hover,
  &:focus {
    box-shadow: ${BUTTON_BLUE_SHADOW};
  }
`

const ButtonBlueGradient = css`
  background: ${({ theme }: { theme: ThemeInterface }) =>
    theme.colors.secondaryGradient};
  color: ${({ theme }: { theme: ThemeInterface }) => theme.colors.white};
  font-weight: ${({ theme }: { theme: ThemeInterface }) =>
    theme.fontWeights.bold};

  &:hover,
  &:focus {
    box-shadow: ${BUTTON_BLUE_SHADOW};
  }
`

const ButtonGreyScale = css`
  background: ${({ theme }: { theme: ThemeInterface }) =>
    theme.colors.silverMedium};
  color: ${({ theme }: { theme: ThemeInterface }) => theme.colors.grayMedium};
  font-weight: ${({ theme }: { theme: ThemeInterface }) =>
    theme.fontWeights.bold};

  &:hover,
  &:focus {
    box-shadow: ${BUTTON_BLUE_SHADOW};
  }
`

const ButtonLight = css`
  background-color: ${({ theme }: { theme: ThemeInterface }) =>
    theme.colors.blueMarineAlpha10};
  color: ${({ theme }: { theme: ThemeInterface }) => theme.colors.brandBlue};

  &:hover,
  &:focus {
    box-shadow: ${BUTTON_BLUE_SHADOW};
  }
  &[disabled] {
    opacity: 0.35;
  }
`

const ButtonOutline = css`
  background-color: ${({ theme }: { theme: ThemeInterface }) =>
    theme.colors.clear};
  border: ${BUTTON_BORDER_WIDTH} solid currentColor;
  color: ${({ theme }: { theme: ThemeInterface }) => theme.colors.brandBlue};
  padding: ${({ theme }: { theme: ThemeInterface }) => theme.spacing.three};

  &:hover,
  &:focus {
    color: ${({ theme }: { theme: ThemeInterface }) =>
      theme.colors.brandBlueDark};
  }
`

const ButtonImg = css`
  background-color: ${({ theme }: { theme: ThemeInterface }) =>
    theme.colors.clear};
  border: none;
`

export const buttonStyleMap = {
  [ButtonStyles.PRIMARY]: ButtonSolid,
  [ButtonStyles.SECONDARY]: ButtonSecondary,
  [ButtonStyles.LIGHT]: ButtonLight,
  [ButtonStyles.BLUE_GRADIENT]: ButtonBlueGradient,
  [ButtonStyles.GREY_SCALE]: ButtonGreyScale,
  [ButtonStyles.CLEAR]: ButtonOutline,
  [ButtonStyles.IMG]: ButtonImg,
}

const ButtonXSmall = css`
  min-width: ${convertPxToRem(35)};
`

const ButtonSmall = css`
  min-width: ${convertPxToRem(108)};
`

const ButtonMedium = css`
  min-width: ${convertPxToRem(140)};
`

const ButtonLarge = css`
  min-width: ${convertPxToRem(208)};
`

const ButtonXLarge = css`
  min-width: ${convertPxToRem(243)};
`
export const buttonSizeMap = {
  [ButtonSizes.XSMALL]: ButtonXSmall,
  [ButtonSizes.SMALL]: ButtonSmall,
  [ButtonSizes.MEDIUM]: ButtonMedium,
  [ButtonSizes.LARGE]: ButtonLarge,
  [ButtonSizes.XLARGE]: ButtonXLarge,
}

export const BrandButton = css`
  border-radius: ${convertPxToRem(20.5)};
  font-size: ${({ theme }: { theme: ThemeInterface }) =>
    theme.fontSize.caption};
  font-weight: ${({ theme }: { theme: ThemeInterface }) =>
    theme.fontWeights.semiBold};
  letter-spacing: ${({ theme }: { theme: ThemeInterface }) =>
    theme.letterSpacing.small};
  line-height: 1;
  padding: ${({ theme }: { theme: ThemeInterface }) => theme.spacing.three};
  transition: ${({ theme }: { theme: ThemeInterface }) =>
    theme.transitions.default};
  text-transform: uppercase;
`

export const styleButtonComponent = (
  BComponent: React.FunctionComponent<ButtonProps>,
) => styled(BComponent)`
  ${ButtonReset};
  ${BrandButton};
  ${({ buttonStyle = ButtonStyles.PRIMARY }: ButtonProps) =>
    buttonStyleMap[buttonStyle]};
  ${({ size = ButtonSizes.SMALL }: ButtonProps) => buttonSizeMap[size]};
  ${zIndex};
`
