import { ConfigType } from '../../config/config.type'
import { SubmitContactFormResult } from './peertje-labs.types'
import { configureSubmitContactFormService } from './submit-contact-form.service'

export type PeertjeLabsServicesType = {
  submitContactForm: (data: FormData) => Promise<SubmitContactFormResult>
}

export const configurePeertjeLabsServices: (
  config: ConfigType['peertjelabs'],
) => PeertjeLabsServicesType = config => {
  return {
    submitContactForm: configureSubmitContactFormService(`/`),
  }
}
