export const scrollIntoView = (
  element: any,
  window: Window,
  cb?: () => void,
) => {
  let scrollTimeout: any
  const rect = element.getBoundingClientRect()
  if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
    if (cb) {
      cb()
    }
  } else {
    const scrollListener: (e: any) => void = e => {
      clearTimeout(scrollTimeout)
      scrollTimeout = setTimeout(() => {
        removeEventListener('scroll', scrollListener)
        if (cb) {
          cb()
        }
      }, 100)
    }
    addEventListener('scroll', scrollListener)
    element.scrollIntoView({
      behavior: 'smooth',
    })
  }
}
