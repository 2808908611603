import axios from 'axios'
import {
  SubmitContactFormResponse,
  SubmitContactFormResult,
} from './peertje-labs.types'
import { transformSubmitContactFormResponse } from './transforms/submit-contact-form-response.transform'

type ConfigureSubmitContactFormService = (
  SUBMIT_CONTACT_FORM_ENDPOINT: string,
) => (data: FormData) => Promise<SubmitContactFormResult>

export const configureSubmitContactFormService: ConfigureSubmitContactFormService = SUBMIT_CONTACT_FORM_ENDPOINT => payload => {
  const headers = {
    Accept: 'application/x-www-form-urlencoded;charset=UTF-8',
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  }

  return axios
    .post<SubmitContactFormResponse>(SUBMIT_CONTACT_FORM_ENDPOINT, payload, {
      headers,
    })
    .then(res => transformSubmitContactFormResponse(res.data))
}
