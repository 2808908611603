import * as React from 'react'

import styled from 'styled-components'
import {
  BrandButton,
  ButtonReset,
  buttonSizeMap,
  styleButtonComponent,
} from './button.style'
import {
  ButtonProps,
  ButtonSizes,
  ButtonStyles,
  ButtonTypes,
} from './button.types'

export const ButtonComponent: React.FunctionComponent<ButtonProps> = ({
  children,
  className,
  disabled = false,
  label = children,
  onClick,
  type = ButtonTypes.BUTTON,
}) => (
  <button
    className={className}
    onClick={onClick}
    disabled={disabled}
    type={type}
  >
    {label}
  </button>
)

export const Button = styleButtonComponent(ButtonComponent)
