import React from 'react'
import styled from 'styled-components'
import { Button } from '../button'
import { ButtonSizes, ButtonStyles } from '../button'

const SendButton = styled(Button)`
  width: 100%;
  background: #f7f7f7;
  margin-top: 2em;
`

const MessageTextArea = styled.textarea`
  width: 100%;
  resize: none;
  padding: 1.2em;
  border-radius: 0.2em;
  font-size: 1.2em;
  border: 0;
  background: #f7f7f7;
`

export const ContactFormSuccessMessage = styled.div`
  color: green;
`

export const ContactFormErrorMessage = styled.div`
  color: red;
`

export const ContactForm: React.FunctionComponent<{
  handleSendMessage: (formData: FormData) => Promise<any>
  errorMessage?: string
  success?: boolean
  loading: boolean
  disabled?: boolean
  messageFieldRef?: React.RefObject<HTMLTextAreaElement>
  backupEmail: string
}> = ({
  handleSendMessage,
  errorMessage,
  success,
  loading,
  disabled = false,
  messageFieldRef,
  backupEmail,
}) => {
  const [messageContent, setMessageContent] = React.useState<string>()
  return (
    <form name="contact" data-netlify>
      <div>
        <MessageTextArea
          name="message"
          ref={messageFieldRef}
          value={messageContent}
          disabled={loading || success}
          onChange={e => setMessageContent(e.target.value)}
          placeholder="..."
          rows={15}
        ></MessageTextArea>
      </div>
      <div>
        <SendButton
          disabled={!messageContent || loading || disabled}
          buttonStyle={ButtonStyles.GREY_SCALE}
          size={ButtonSizes.LARGE}
          onClick={() => {
            const data = new FormData()
            data.set('form-name', 'contact')
            data.set('message', messageContent || '')
            return handleSendMessage(data).then(() => setMessageContent(''))
          }}
        >
          Send
        </SendButton>
      </div>
      {(errorMessage || success) && (
        <div style={{ textAlign: 'center', padding: '1em' }}>
          {errorMessage && (
            <ContactFormErrorMessage>
              There was an error sending the message. If this persists, try
              emailing at <a href={`mailto:${backupEmail}`}>{backupEmail}</a>.
            </ContactFormErrorMessage>
          )}
          {success && (
            <ContactFormSuccessMessage>Message Sent.</ContactFormSuccessMessage>
          )}
        </div>
      )}
    </form>
  )
}
